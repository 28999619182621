import React from "react"
import SEO from "../components/seo"
import Video from "../components/Video"
import VideoMobile from "../components/VideoMobile"

export default function RlGrime(props) {
  return (
    <div id="rlgrime-arcus">
      <VideoMobile
        title="RL Grime x Graves, Arcus • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/323850775?title=0&muted=0"
        allowsInlineMediaPlayback="true"
      />
      <Video
        title="RL Grime x Graves, Arcus • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/323850775?background=1&autoplay=1&loop=1&byline=0&title=0&muted=0"
      />
    </div>
  )
}
